import React from 'react'

const VillaPackage = () => {
  return (
    <div className='commpak_mn'>
      <h2>Upcoming Soon..............</h2>
    </div>
  )
}

export default VillaPackage
